import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold24, bold32, bold48, bold64 } from "./typography"
import { baseGridStyles } from "./base"

const StyledSignUpHeader = styled.h2`
  margin-top: 0;
  grid-column: span 4;
  ${bold24};
  @media screen and (min-width: ${breakpoints.sm}px) {
    ${bold32}; 
    grid-column-start: 2;
    grid-column-end: 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    ${bold48}; 
    grid-column: span 12;
    text-align: center;
    
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    ${bold64};
  }
`

const StyledSignUpLede = styled.p`
  text-align: left;
  grid-column: span 4;
  color: var(--white);
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 2.4rem;
    text-align: center;
    grid-column: span 12;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column-start: 5;
    grid-column-end: 9;
  }

  &.ad {
      font-weight: 700;
      &:first-of-type {
          margin-bottom: 0.8rem
      }
  }
`
const StyledSignUpForm = styled.section `
    ${baseGridStyles}
    background-color: #44076D;
    max-width: 100%;
    
    @media screen and (min-width: ${breakpoints.l}px) {
        max-width: 100%;
    }
    h2 {
        grid-column: span 4;
        color: var(--white);
        text-align: center;
        margin-top: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 12;
        }
    }
    form {
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column-start: 1;
            grid-column-end: 13;
        }
        fieldset{
            @media screen and (min-width: ${breakpoints.md}px) {
                ${baseGridStyles}
                padding: 0;
                @media screen and (min-width: ${breakpoints.md}px) {
                    padding: 0;
                }
            }

            div:nth-child(1) {
                @media screen and (min-width: ${breakpoints.md}px) {
                    grid-column: 2 / 5;
                }
                @media screen and (min-width: ${breakpoints.l}px) {
                    grid-column: 4 / 6;
                }
            }
            div:nth-child(2) {
                @media screen and (min-width: ${breakpoints.md}px) {
                    grid-column: 5 / 8;
                }
                @media screen and (min-width: ${breakpoints.l}px) {
                    grid-column: 6 / 8;
                }
            }
        }

        input[type="text"],
        input[type="email"] {
            padding: 1.2rem 0 1.2rem 1.2rem;
            margin-bottom: 1.6rem;
            @media screen and (min-width: ${breakpoints.md}px) {
                margin-bottom: 0;
            }
        }

        button {
            background: var(--light-purple-mid-dark);
            margin-top: 0;
            
            width: 100%;
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 8 / 12;
            }
            @media screen and (min-width: ${breakpoints.l}px) {
                grid-column: 8 / 10;
            }

            &:focus,
            &:hover {
                background: var(--light-purple-mid-dark);
            }
            &:disabled {
                background: var(--light-purple-darker);
                opacity: 0.4;
                width: 100%;
            }
        }
        .error-message {
            top: 1.4rem;
            right: 1rem;
        }
    }
`

export {
    StyledSignUpForm,
    StyledSignUpHeader,
    StyledSignUpLede
}