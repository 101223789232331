import styled from "styled-components"
import breakpoints from "./breakpoints"
import { regular14, regular16, bold14, regular11, bold16 } from "./typography"
import selectarrow from "../css/selectarrow.svg"
import selectarrowWhite from "../css/selectarrowWhite.svg"
import { baseGridStyles } from "./base";
import { StyledTab, StyledTabSelected, StyledTabsList } from "./tabs";


const ContactSectionHeading = styled.div`
  text-align: ${props => props.align ? 'center' : 'left'};
  margin:0;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
    text-align: center;
    margin: 0 auto;
  }
`
const ContactSectionLedeDiv = styled.div`
  text-align: center;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 6;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
  }

  p {
    text-align: left;
    margin-bottom: 1.6rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      text-align: center;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
  ul.contact-list {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1.6rem;
  }
`
const ContactSection = styled.div`
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
  }

  p {
    text-align: left;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .react-tabs.contact {
    .react-tabs__tab-list {
        ${StyledTabsList}
        margin-bottom: 1.6rem;
        @media screen and (min-width: ${breakpoints.md}px) {
          justify-content: center;
          margin-bottom: 2.4rem;
        }
    }
    .react-tabs__tab {
      ${StyledTab}
      margin-left:-0.8rem;
    }
    .react-tabs__tab--selected {
      ${StyledTabSelected}
    }
  }
`
const ConsultationSection = styled.div`
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
  }

  p {
    text-align: left;
    margin-top: 1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`
const StyledForm = styled.form`
  grid-column: span 4;
  padding: 0;
  margin-top: ${props => props.marginTop ? `1.4rem;` : `0;`};
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
    padding: 0;
    margin-top: ${props => props.marginTop ? `2.2rem;` : `0;`};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
  }
  h2 {
    margin-bottom: 1.6rem;
  }

  &.preBooking {
    ul { 
      margin-bottom: 2.4rem;
      li {
        margin-bottom: 0;

        grid-column: 1 / 6;
        @media screen and (min-width: ${breakpoints.sm}px) {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3){
            grid-column: 1 / 6;
          }
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3){
            grid-column: 1 / 6;
          }
        }
      }
    }
  }
  .pricing-table {
    ul li {
      margin-bottom: 1.2rem;
    }
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0.8rem;

    
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: grid;
      grid-column: span 6;
      grid-template-columns: repeat(4,1fr);
      column-gap: calc((16 / 672) * 100%);
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular16};
    }
    li {    
      grid-column: 1 / 6;
      margin-bottom: 1.6rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        &:nth-child(1){
          grid-column: 1 / 3;
        }
        &:nth-child(2){
          grid-column: 3 / 5;
        }
        &:nth-child(3){
          grid-column: 5 / 9;
        }
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        &:nth-child(1){
          grid-column: 1 / 4;
        }
        &:nth-child(2){
          grid-column: 5 / 8;
        }
        &:nth-child(3){
          grid-column: 9 / 13;
        }
      }
    }
  }
  ul.modality-type {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    grid-row-gap: 0;
    ul {
      margin-top: 0;
      li {
        margin-bottom: 0;
      }
    }
  }
  ul.rating-list {
    
    display: inline-flex;
    width: 100%;
    margin-top: 0;
    grid-gap: 0;
    grid-row-gap: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: flex;
      justify-content: space-between;
    }

    li {
      width: 20%;
      margin-bottom: 0.8rem;
      text-align: center;
      margin-bottom: 0;
      &:nth-child(1) {
        label span {
          background-color: var(--light-green-mid);
        }
      }
      &:nth-child(2) {
        label span {
          background-color: var(--light-green-lighter);
        }
      }
      &:nth-child(3) {
        label span {
          background-color: var(--gold-light);
          
        }
      }
      &:nth-child(4) {
        label span {
          background-color: var(--gold-mid);
        }
      }
      &:nth-child(5) {
        label span {
          background-color: var(--pink-mid);
        }
      }
    }

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label span {
      display: inline-block;
      background-color: var(--grey-lighter);
      padding: 3px 10px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 2px solid var(--grey-dark);
      border-radius: 50%;
    }
    input[type="radio"]:checked + span {
      background-color:var(--white);
      border-color: var(--light-green-dark);
    }

    input[type="radio"]:focus + label {
      border: 2px dashed #444;
    }
  }

  ul.rating-pain-list {
    display: inline-flex;
    width: 100%;
    margin-top: 0;
    align-items: center;
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: flex;
      justify-content: space-between;
      
    }

    li {
      margin-bottom: 0.8rem;
      width: 16%;
      text-align: center;

      &:nth-child(1) {
        label span {
          background-color: var(--light-green-mid);
          color: var(--light-green-mid);
        }
      }
      &:nth-child(2) {
        label span {
          background-color: var(--light-green-lighter);
          color: var(--light-green-lighter);
        }
      }
      &:nth-child(3) {
        label span {
          background-color: var(--gold-light);
          color: var(--gold-light);
        }
      }
      &:nth-child(4) {
        label span {
          background-color: var(--gold-mid);
          color: var(--gold-mid);
        }
      }
      &:nth-child(5) {
        label span {
          background-color: var(--pink-mid);
          color: var(--pink-mid);
        }
      }
      &:nth-child(6) {
        label span {
          background-color: var(--red-mid);
          color: var(--red-mid);
        }
      }
    }

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label span {
      display: inline-block;
      
      padding: 3px 10px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 2px solid var(--grey-dark);
      border-radius: 50%;
    }

    input[type="radio"]:checked + span {
      background-color:var(--white);
      color:var(--white);
      border-color: var(--light-green-dark);
    }

    input[type="radio"]:focus + label {
      border: 2px dashed #444;
    }
  }


  ul.rating-pain-reference {
    display: inline-flex;
    gap: 8px;
    margin-top: 0;
    width: 100%;
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: flex;
      margin-bottom: 1.6rem;
      /* border-bottom: 1px solid var(--grey-mid); */
    }
    li {
      text-align: center;
      width: 16%;
      ${regular11}
      color: var(--grey-dark);
      font-family: sans-serif, Arial;
      line-height: 1.3rem;
    }
  }
  ul.rating-reference {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-top: 0.8rem;
    grid-gap: 0;
    grid-row-gap: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
    }

    li {
      ${regular11}
      color: var(--grey-dark);
      font-family: sans-serif, Arial;
      width: 20%;
      text-align: center;
      line-height: 1.3rem;
      }
    }
  }

  .intl-tel-input{ 
    ${regular16};
    display: block;
    .country-list{
      display: block;

      li{
        margin-bottom: 0;
      }
    }
    .hide.country-list{
      display: none;
    }

    .selected-flag {
      justify-content: end;
    }
  }
  input.form-control {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--grey-mid-dark);
    padding: 1rem;
    margin-bottom: 2.4rem!important;
    padding-left: 42px!important;

    &:focus {
      border: 0;
      outline: none;
      border-bottom: 1px solid var(--light-green-mid);
    }
  }

  p.forgot-password {
    margin-top: 3.2rem;
    text-align: center;
  }
`

const StyledFieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`
const StyledInput = styled.input`
  border: 0;
  border-bottom: 1px solid var(--grey-mid-dark);
  padding: 0.4rem 0 1.2rem 0;
  border-radius: 0;
  ${regular16};

  &::placeholder {
    color: var(--grey-mid);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border-bottom: 1px solid var(--grey-darker);
    -webkit-text-fill-color: var(--grey-darker);
    box-shadow: 0 0 0 100rem var(--white) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    border: 0;
    outline: none;
    border-bottom: 1px solid var(--light-green-mid);
  }

  &[type="text"],
  &[type="email"],
  &[type="password"],
  &[type="tel"],
  &[type="date"] {
    display: block;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  &[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    margin-bottom: 0;
    padding: 0;
    border-bottom: 0;
    margin-right: -0.5rem;
    position: relative;
    display: inline;
  }

  &[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    margin-right: 0.9rem;
    margin-left: 0.1rem;
    position: relative;
    padding: 0;
    height: 1.4rem;
    border-bottom: 0;

    &:required {
      box-shadow:none;
    }
  }

  &[type="radio"]:after {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1.5rem;            
    top: 0;
    left: -1.4rem;
    position: relative;
    background-color: var(--white);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--light-green-darker);
    margin-right: 0;
  }

  &[type="radio"]:before,
  &[type="radio"]:checked:before {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1.5rem;
    top: 0;
    left: 0;
    position: relative;
    background-color: var(--white);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--light-purple-darkest);
  }


  &[type="radio"]:checked:after {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.3rem;
    border-radius: 1.5rem;
    top: -0.3rem;
    left: -1.4rem;
    position: relative;
    background-color: var(--light-green-darker);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--light-green-darker);
  }

  &[type="checkbox"]:before {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 0;
    top: 0;
    left: 0;
    position: relative;
    background-color: var(--white);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--light-green-darker);
  }
  
  &[type="checkbox"]:checked:before {
    background: var(--light-green-darker);
  }

  &[type="checkbox"]:checked:after {
    position: relative;
    -webkit-transform: rotate(38deg);
    -ms-transform: rotate(38deg);
    transform: rotate(38deg);
    border-style: solid;
    border-width: 0 0.2rem 0.2rem 0;
    border-color: #fff;
    content: "";
    display: block;
    position: absolute;
    height: 1rem;
    left: 0.4rem;
    top: 0.4rem;
    width: 0.6rem;
  }

  /* &[type="radio"]:focus,
  &[type="checkbox"]:focus {
    &:before{
      outline: 2px solid Highlight;
      outline: 2px solid -webkit-focus-ring-color;
    }
  } */
`

const StyledTextArea = styled.textarea`
  border: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--grey-mid-dark);
  padding: 0.4rem 0 1.2rem 0;
  border-radius: 0;
  ${regular14};
  margin-bottom: 1.6rem;

  &::placeholder {
    color: var(--grey-mid);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border-bottom: 1px solid var(--grey-darker);
    -webkit-text-fill-color: var(--grey-darker);
    box-shadow: 0 0 0 100rem var(--white) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    border: 0;
    outline: none;
    border-bottom: 1px solid var(--light-green-mid);
  }
`

const StyledLabel = styled.label`
  overflow: hidden;
  cursor: pointer;
  color: ${props => props.isEmpty ? `var(--red-light)` : `var(--grey-dark)`};
  span a {
    text-decoration: none;
    color: var(--light-green-darker);
    display: inline-block;
    border-bottom: 1px dotted var(--white);
    position: relative;

    &:hover,
    &:active,
    &:focus {
      color: var(--light-green-dark);
      border-bottom: 1px dotted var(--light-green-dark);
      &::after {
        color: var(--light-green-dark);
        border-bottom: 0rem dotted var(--light-green-dark);
      }
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: 0;
      display: inline;
    }
  }
`

const StyledLabelHeader = styled.span`
  ${bold16};
  display: ${props => props.hidden ? `none` : `block`};;
  color: ${props => props.isEmpty ? `var(--red-light);` : `var(--grey-dark);`};
  ${props =>
    props.required
      ? "&::after{content: '* ';margin-left:0.2rem;color:var(--light-green-darker)}"
      : ""};
  strong {
    
  }

&.checkbox,
&.radiolist {
    display: inline-block;
    margin-bottom: 1.6rem;
  }
&.checkbox {
  margin-bottom: 1.6rem;
  margin-top: -2.4rem;
  margin-left: 2.4rem;
  display: block;
}
`

const StyledLabelHeaderError = styled.span`
  ${bold14};
  color: var(--red-light);
  ${props =>
    props.required
      ? "&::after{content: '* ';margin-left:0.2rem;color:var(--red-light)}"
      : ""};
`

const StyledFormElementWrapper = styled.div`
  position: relative;

  ul {
    ${baseGridStyles}
    padding: 0;
    grid-gap: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0;
    }
    li {
      grid-column: span 6;
    }
  }

  .error-message {
    position: absolute;
    top: -0.1rem;
    right: 0;
    color: var(--red-light);
    ${regular14};
  }
`


const StyledOption = styled.option`
  /* color: green; */
`
const CheckboxListWrapper = styled.div`
  p {
    width: 100%;
    margin: 0 0 1.6rem;
  }
`

const ErrorMessage = styled.div`
  p {
    text-align: center;
    color: var(--red-light);
  }
`

const StyledSelectWrapper = styled.div`
  position: relative;
`
const StyledSelect = styled.select`
  appearance: none;
  display: block;
  margin-top: 0.8rem;
  margin-bottom: 2.4rem;
  padding-top: 2.4rem;
  width: 100%;
  padding: 0 0.8rem;
  z-index: 1;
  ${regular16};
  border: 1px solid var(--grey-light);
  height: 3.4rem;
  background: url(${selectarrow}) 99% 4px no-repeat;
  background-size: 20px 23px;
  padding-right: 3rem;
  ${props => props.hasBackground && "color:var(--white);"}
  &:focus {
    outline: none;
    ${props => props.hasBackground ? "border: 1px solid var(--white);" : "border: 1px solid var(--light-green-darkest);"}
  }

  &.hasBackgroundColor {
    background: url(${selectarrowWhite}) 99% 4px no-repeat;
    margin: 0;
  }
`

export {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledTextArea,
  StyledLabel,
  StyledLabelHeaderError,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
  ContactSectionHeading,
  ContactSectionLedeDiv,
  CheckboxListWrapper,
  ContactSection,
  ErrorMessage,
  ConsultationSection
}
