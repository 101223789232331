import { css } from "styled-components"
import breakpoints from "./breakpoints"
import { regular16, regular12 } from "./typography";

export const StyledAccordion = css`
    .accordion__item h2:first-of-type {
        margin-top: 0;
    }
    .accordion__item + .accordion__item {
        // border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .accordion__button {
        cursor: pointer;
        padding: 1.6rem 0 0;
        width: 100%;
        text-align: left;
        border: none;
        ${regular16}
        color: var(--light-purple-darker);

        &.audio-module-item {
            padding: 1.6rem 0;

            @media screen and (min-width: ${breakpoints.sm}px) {
                position: relative;
            }
            span {
                color: var(--black);
                ${regular12}
                display: block;
                @media screen and (min-width: ${breakpoints.sm}px) {
                    position: absolute;
                    right: 0;
                    top: 1.7rem;
                }
            }


        }

        .permission-sleep-modal & {
            color: var(--white);
            position: relative;
            padding: 0.8rem 1.6rem 0.8rem 0;
            &:before {
                position: absolute;
                right: 0;
                top: 1.8rem;
            }

            h3 {
                margin: 0;
            }
        }
    }

    .accordion__button:hover {
        /* background-color: var(--grey-lightest); */
    }

    .accordion__button:before {
        display: inline-block;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
        position: relative;
        top: -2px;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg);
    }
    .accordion__panel {
        padding: 1.6rem 0;
        animation: fadein 0.35s ease-in;

        p {
            @media screen and (min-width: ${breakpoints.l}px) {
                margin-left: 2.2rem;
            }
        }

        .order-modal {
            p {
                @media screen and (min-width: ${breakpoints.l}px) {
                    margin-left: 0;
                }
            }
        }
        ul {
            margin-top: 0;
        }

        .embed-container {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
            margin-bottom: 1.6rem;

            @media screen and (min-width: ${breakpoints.l}px) {
                margin-bottom: 3.2rem;
            }

            iframe, 
            object, 
            embed { 
                position: absolute; 
                top: 0; 
                left: 0; 
                width: 100%; 
                height: 100%; 
            }
        }
    }
`;