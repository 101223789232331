import styled from "styled-components"
import breakpoints from "../styledComponents/breakpoints"
import { regular12, regular13, regular16, bold18, bold24, semibold16 } from "../styledComponents/typography"
const StyledSaleIcon = styled.span`
	display: inline-block;
	background: #f53479;
	background: var(--notification-blue);
	color: var(--white);
	width: 6.5rem;
	text-align: center;
	padding: 0.4rem;
	${regular12}
	vertical-align: middle;
	font-weight: 700;
.sale {
	
}

@keyframes beat {
	from {	transform: rotate(-20deg) scale(1); }
	to {	transform: rotate(-20deg) scale(1.1); }
}
`

export {
    StyledSaleIcon
}