import React, { useLayoutEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import Moment from 'react-moment'
import {
    StyledSiteNotification
} from "../styledComponents/siteNotification"
import { StyledCloseModalButton } from "../styledComponents/button"
import Icon from "../components/icon"
import { analyticClickEvent } from "../components/googleAnalytics"


const SiteNotification = ({ isNotificationSet, createNotificationCookie, energyBoostData }) => {

    let input = energyBoostData?.nodes[0].eventsMetaData.datetime
    if (input) {
        let fields = input.split('_')
        var date = fields[0];
        var time = fields[1];
        var timeNoSec = time.slice(0, -3);
    }
    return (
        <>
            <StyledSiteNotification className={`notification`} isNotificationSet={isNotificationSet}>
                <p><strong><span style={{ 'display': 'block' }}>{energyBoostData?.nodes[0].title} - <Moment format="dddd, MMM, Do">{(date)}</Moment> {' '}{timeNoSec} {' '} {energyBoostData?.nodes[0].eventsMetaData.timezone}</span> <Link onClick={() => { navigate(`https://www.karinagrant.co.uk/events/quantum-touch?id=cG9zdXoxMTIyNw==`); createNotificationCookie('cG9zdXoxMTIyNw');analyticClickEvent("click", "link", "site notification", energyBoostData?.nodes[0].title + ' - ' + date + ' notification') }} to={'https://www.karinagrant.co.uk/events/quantum-touch?id=cG9zdXoxMTIyNw=='}>Find out more 
                <span style={{'position':'absolute','top':'-50000px','left':'-50000px'}}>about the {energyBoostData?.nodes[0].title}</span> &gt;&gt;</Link></strong> </p>
                <StyledCloseModalButton aria-label="Close the notification" onClick={() => { createNotificationCookie('cG9zdXoxMTIyNw'); analyticClickEvent("click", "button", "close site notification", energyBoostData?.nodes[0].title + ' - ' + date + ' notification') }}>
                    <Icon name="cross" />
                </StyledCloseModalButton>
            </StyledSiteNotification>
        </>
    )
}

export default SiteNotification