import { css } from "styled-components"
const StyledTabsList = css`
    display: flex;
    overflow-x: auto;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--light-purple-darker);
`

const StyledTab = css`
    flex-shrink: 0;
    list-style-type: none;
    padding: 0.8rem 1.6rem;
    color: var(--light-purple-darker);
    cursor: pointer;
    font-weight: 700;
    margin-left: 0.6rem!important;
    abbr {
        text-decoration: none;
    }
`

const StyledTabSelected = css`
    background: var(--light-purple-darker);
    color: #fff;
    border-radius: 1rem 1rem 0 0;


`


export {
    StyledTabsList,
    StyledTab,
    StyledTabSelected
}