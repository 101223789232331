import styled, { css } from "styled-components"
import breakpoints from "./breakpoints"
import {
  bold16,
  bold18,
  bold20,
  bold24,
  bold28,
  bold32,
  bold40,
  bold48,
  bold64,
  regular12,
  regular14,
  regular16,
  regular18,
} from "./typography"
import { createGlobalStyle } from "styled-components"
import { primaryFontFamily, secondaryFontFamily } from "../styledComponents/typography"
import { StyledAccordion } from "../styledComponents/accordion"

export const GlobalStyle = createGlobalStyle`

  *,
  &:after,
  &:before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    ${primaryFontFamily}
    background: ${props => !props.mode ? "var(--white)"  : 'var(--light-purple-darkest)'};
  }
  
  body{
    min-width: 32rem;
  }

  /* Link */
  a {
    color: var(--light-purple-darker);
    text-decoration: none;
    word-break: break-word;
    border-bottom: 1px dotted var(--light-green-dark);
    &:visited {
      color: var(--light-green-darkest);
      border-bottom: 1px dotted var(--light-green-darker);
    }

    &:hover, 
    &:active,
    &:focus {
      color: var(--light-green-dark);
      border-bottom: 1px dotted var(--light-green-dark);
    }
  }

 /* colors */
  :root {
    --light-green-light: #effff1;
    --light-green-lightest: #c4d8dc;
    --light-green-lighter: #ACDF87;
    --light-green-mid: #215E29;
    --light-green-dark: #037938;
    --light-green-darkest: #628a23;
    --light-green-darker: #067E31;
    --light-green-darkest: #037938;
    
    --grey-lightest: #f5f5f5;
    --grey-lighter: #eeece9;
    --grey-light: #ddd9d4;
    --grey-mid: #bab2a8;
    --grey-mid-dark: #888888;
    --grey-dark: #3d3b37;
    --grey-darker: #242220;
    --grey-darkest: #131211;

    --green-mid: #30d35e;
    --green-light: #53EB7E;
    --white: #ffffff;
    --black: #000000;
    --gold-mid: #F3B844;
    --gold-light: #FFCC00;
    --green-mid-dark: #85B254;
    --red-light: #ef2d79;
    --red-mid: #EF2D2D;

    --light-purple-mid: #e6e2f6;
    --light-purple-mid-dark: #953491;
    --light-purple-dark: #9c27b0;
    --light-purple-darker: #953491;
    --light-purple-darkest: #44076D;

    --green-strong: #067E31;
    --green-mid: #78cbc9;

    --pink-mid: #f53379;
    --pink-light: #f67eab;

    --blue-mid: #33ace4;

    --sleep-header-background: #3d3b37;
    --notification-blue: #0c31e5;
    --notification-red: #EF2D2D;
  }

  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  blockquote,
  figure,
  figurecaption,
  pre, dl {
    margin: 0;
    @media screen and (min-width: 1304px) {
      margin: 0 auto;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.6rem;
    ${bold16};
    color: var(--light-green-darker);
  }
  h1 {
    ${bold24};
    margin-bottom: 0.4rem;
    margin-top: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold32}; 
      margin-bottom: 0.8rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${bold48}; 
      margin-bottom: 1.6rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold64};
    }
  }
  h2 {
    ${bold20};
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold28};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold40};
    }
  }
  h3 {
    ${bold18};
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold20};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold24};
    }
  }
  h4 {
    ${bold16};
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold18};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold20};
    }
  }
  h5 {
    ${bold16};
    font-weight: 840;
    @media screen and (min-width: ${breakpoints.sm}px) {
      font-weight: 900;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold18};
    }
  }
  h6 {
    ${bold16};
    font-weight: 760;
    @media screen and (min-width: ${breakpoints.sm}px) {
      font-weight: 840;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      font-weight: 900;
    }
  }

  p {
    color: var(--grey-darker);
    margin-top: 0;
    margin-bottom: 0;
    ${regular16};
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
    }
  }
  em {
    font-style: italic;
  }
  strong {
    font-family: "Raleway Medium", Arial, Helvetica, sans-serif;
    font-weight: 700;
  }
  ul,
  ol {
    ${regular16};
    padding-left: 2rem;
    margin-top: 1.6rem;
    transition: padding-left 0.25s ease-out;

    @media screen and (min-width: 912px) {
      padding-left: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-left: 2rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
      padding-left: 2rem;
    }
    @media screen and (min-width: 1404px) {
      padding-left: 4rem;
    }
  }
  ul ul,
  ol ol, 
  ul ol,
  ol ul {
    padding-left: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding-left: 2rem;
    }
  }
  /* ol li::marker {
    font-size: 1.4rem;
  } */
  ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol {
    list-style-type: lower-roman;
  }
  li ul,
  li ol {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 0;
    margin-right: 0;
  }
  figure.wp-block-pullquote {
    blockquote {
        background: var(--grey-lightest);
        p {
          font-weight: 700;
        }
      }
  }
  blockquote {
    margin: 1.6rem 0;
    padding: 1.6rem;
    border-left: 4px solid var(--grey-light);
    @media screen and (min-width: ${breakpoints.sm}px) {
      width: calc((442 / 720) * 100vw);
      margin-left: auto;
      margin-right: auto;
      padding: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      width: calc((480 / 1024) * 100vw);
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      width: calc((642 / 863) * 100%);
    }
    p {
      ${bold16}
      margin-left: 0;
      margin-top: 0;
      margin-right: 0;
      font-style: italic;
      font-weight:500;
      
      @media screen and (min-width: ${breakpoints.md}px) {
        margin-right: 0;
      }
    }
    cite {
      display: block;
      margin-top: 0.8rem;
      ${regular14};
      ${secondaryFontFamily}
      font-weight: 900;
      font-style: italic;
      margin-left: 0;
      strong {
        font-weight: 900;
      }
    }
  }
  
  figure {
    margin-top: 1.6rem;
    &:first-child{
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
    
    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }

    .gatsby-image-wrapper {
      vertical-align: top;
    }

    &.wp-block-embed.is-type-video {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 3rem;
      height: 0;
      overflow: hidden;
      margin: 0;
      width: 100%;
      grid-column: span 4;

      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 2 / 12;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        /* padding-bottom: 45%; */
      }

      &:first-of-type {
        margin-top: 0;
      }

      article & {
        margin-top:1.6rem;
        @media screen and (min-width: ${breakpoints.l}px) {
          padding-bottom: 56.25%;
          width: 100%;
        }
      }

      iframe,  
      object,  
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
  figcaption {
    ${regular12};
    line-height: 1.6rem;
    color: var(--grey-mid-dark);
    text-align: center;
    margin: 0.8rem 0 0;
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular12};
    }
  }
  pre {
    ${regular16};
    font-family: monospace;
    overflow-x: scroll;
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
    }
  }
  dl {
    ${regular16};
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
    }

    dt {
      margin-top: 1.6rem;
      ${bold16};
      font-style: italic;
    }
    dd {
      margin: 0;
    }
  }


  .accordion {
    ${StyledAccordion}
  }
`

export const StyledMain = styled.main`
  min-height: calc(100vh - 38.1rem);
  padding: 0;
  overflow: hidden;
  margin-top: ${props => props.isOffer ? '4rem' : !props.hasHero ? "0" : '-4.8rem' };

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: ${props => props.isOffer ? '6rem' : !props.hasHero ? "0" : '-7.4rem' };
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-top: ${props => props.isOffer ? '8rem' : !props.hasHero ? "0" : '-12rem' };
  }

  ${props => props.landerType === 'sleepLander' &&
    `@media screen and (min-width: ${breakpoints.md}px) {
      &:after {
        content: " ";
        display: block;
        width: auto;
        bottom: 0;
        left: 80%;
        right: 0;
        top: 10%;
        position: absolute;
        background-image: url(/images/backgrounds/arrowdown.png);
        background-repeat: no-repeat;
        background-position:  0% 0;
      }
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      &:after {
        left: 75%;
        top: 25%;
      }
    }
  `
  }
`

export const baseGridStyles = css`
  display: grid;
  grid-column: span 4;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(4,1fr);
  grid-row-gap: 1.6rem;
  padding: ${props => (props.noPadding ? "0" : "1.6rem 1.6rem")};

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
    grid-template-columns: repeat(6,1fr);
    column-gap: calc((16 / 672) * 100vw);
    grid-row-gap: 1.6rem;
    padding: ${props => (props.noPadding ? "0" : "2.4rem calc((24 / 720) * 100vw)")};
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0;
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);
    column-gap: calc((16 / 1024) * 100vw);
    padding: ${props => (props.noPadding ? "0" : "2.4rem calc((24 / 1024) * 100vw)")};
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-row-gap: 1.6rem;
    padding: 3.2rem;
    max-width: 136.8rem;
    margin: 0 auto;
  }
`;


export const sectionHeaderFontIncrease = css`
      ${bold24};
      margin-bottom: 0;
      margin-top: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        ${bold32};         
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold48}; 
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        ${bold64};
      }
`;

export const strikethrough = css`
  color: var(--red-mid);
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
    // transform:rotate(0deg);
  }
`;

export const StyledBlockQuote = styled.blockquote`
  border-left: 0;
  ${props => props.noBackground ? 'background-color: none;' : 'background-color: var(--grey-lightest);'}

  padding: 1.6rem -1.6rem;
  margin: 1.6rem 0 0;
  position: relative;

  &.modal {
    margin: 1.6rem;
    text-align: center;
    cite {
      color: #000;
    }
  }
  /* ${props => (props.img ? "background-image: url(`props.img') no-repeat; " : "2.4rem calc((24 / 720) * 100vw)")}; */

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 1.6rem auto;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 2.4rem auto;
    width: 100%;
    max-width: 70rem;
  }
  
  p {
    font-style: normal;
    font-weight: 500;
    margin-top: 1.6rem;
    strong {
      ${secondaryFontFamily}
      font-weight: 900;
    }
  }
  .single-quote {
    width: 4rem;
    height: 4rem;
    fill: var(--light-green-dark);
  }

  cite {
    margin-top: 1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-top: 2rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 2.8rem;
    }
  }

  img {
    position: absolute;
    bottom: 1rem;
    width: 5rem;
    top: auto;
    right: 2rem;
    border-radius: 50%;
    @media screen and (min-width: ${breakpoints.md}px) {
      width: 7rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      width: 8rem;
      bottom: 2rem;
      right: 2rem;
    }
  }

  .react-player {
    margin-top: 1.6rem;
    width: 100%;
    height: 100%;
    
    // .react-player__preview {
    //   min-height: 230px;
    //   @media screen and (min-width: ${breakpoints.sm}px) {
    //     min-height: 360px;
    //   }
    //   @media screen and (min-width: ${breakpoints.l}px) {
    //     min-height: 480px;
    //   }
    // } 
  }
`

export const StyledTootTip = styled.span`
  position: relative;
  
  &:hover {
    cursor: pointer;
  }
  svg {
    position: absolute;
    right: -2.2rem;
    top: 0.2rem;

    &:hover {
      path.outer {
        fill: var(--orange-mid);
      }
      path.qmark {
        fill: var(--orange-mid);
      }
    }
  }
`

export const StyledToolTipData = styled.div`
    min-width:240px; 
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:10px 20px;
    color:#444444;
    background-color: var(--grey-lightest);
    ${regular16}
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    display:none;
  
  .tooltip-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent;
  }
`