import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import {
  Footer,
  NavMenu,
  NavList,
  NavListItem,
  FooterLogoCopyright,
  FooterCopyright,
  FooterHygieneList,
  FooterHygieneListItem,
} from "../styledComponents/footer"
import KarinaGrantLogo from "./logo"
import { StyledButtonLink } from "../styledComponents/button"

const FooterContent = ({ isCookieSet }) => {
  const identity = useIdentityContext()
  const {
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "Footer Menu" }) {
        name
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
                id
              }
            }
            id
          }
        }
      }
    }
  `)

  return (
    <Footer isCookieSet={isCookieSet}>
      {/* <FooterContainer> */}
        <NavMenu>
          {nodes.map(menuItems => {
            if (menuItems.childItems.nodes.length) {
              return (
                <NavList key={menuItems.id} hasChildren>
                  {menuItems.childItems.nodes.map(childItem => {
                    return (
                      <>
                      <NavListItem key={childItem.id}>
                        <Link to={childItem.url}>{childItem.label}</Link>
                      </NavListItem>
                      </>
                    )
                    })}
                </NavList>
                
              )
            }
           
            return null
          })}
           {identity.user ?
              <>
               <StyledButtonLink className="logout" onClick={identity.logout}>Log out</StyledButtonLink>
              </>
            :
            <>
               
               <StyledButtonLink className="logout" href="/login">Log in</StyledButtonLink>
              </>
            }
          <FooterLogoCopyright>
            <KarinaGrantLogo color="white"/>
            <FooterCopyright>&copy;{new Date().getFullYear()}</FooterCopyright>
          </FooterLogoCopyright>
          <FooterHygieneList>
            <FooterHygieneListItem key="privacy">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </FooterHygieneListItem>
            <FooterHygieneListItem key="terms">
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            </FooterHygieneListItem>
            <FooterHygieneListItem key="cookies">
              <Link to="/cookie-policy">Cookie Policy</Link>
            </FooterHygieneListItem>
          </FooterHygieneList>
        </NavMenu>
    </Footer>
  )
}

export default FooterContent
