import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold16, regular11, regular14, regular16, regular18, regular24, tertiaryFontFamily } from "./typography";

const StyledSiteNotification = styled.div`
    background: var(--notification-red);
    padding: 0.8rem 0;
    top: 0;
    left: 0;
    z-index: 10;
    transition: height 0.4s linear;
    width: 100%;

    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 1.6rem 0;
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0.8rem 0;
    }
    p {
        color: #fff;
        ${regular16}
        text-align: center;
        padding-right: 4rem;
        padding-left: 4rem;
        font-weight: 700;
        @media screen and (min-width: ${breakpoints.md}px) {
            text-align: center;
            padding-right: 0;
            ${regular18}
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            ${regular24}
        }
        strong {
            ${tertiaryFontFamily}
            font-weight: 900;         
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
`

export {
    StyledSiteNotification
}