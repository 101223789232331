export const analyticClickEvent = (action, category, label, value) => {
  // console.log('action: ', action, 'category: ', category, 'label: ',label)
  window.gtag("event", action, {
    'event_category': category,
    'event_label': label,
    'event_value': value
  })
}

export const analyticPurchaseEvent = (
  action,
  category, 
  label,
  transaction_id, 
  transaction_value, 
  transaction_currency, 
  transaction_tax,
  itemid,
  item_name,
  item_price
) => {
  // console.log('action:',action, 'category', category, 'label', label, 'transaction_id: ', transaction_id, 'transaction_value: ', transaction_value, 'transaction_currency: ',transaction_currency,'transaction_tax',transaction_tax)
  window.gtag("event", action, {
    "event_category": category,
    "event_label": label,
    "transaction_id": transaction_id,
    "affiliation": "Google online store",
    "value": transaction_value,
    "currency": transaction_currency,
    "tax": 1.24,
    "shipping": 0,
    "items": [
      {
        "id": itemid,
        "name": item_name,
        "list_name": "Karina Grant Shop",
        "brand": "Karina Grant",
        "item_price" : item_price
      }
    ]
  })
}

export const analyticBeginCheckout  = (
  currency,
  value, 
  item_id,
  item_name,
  item_price
) => {
  window.gtag("event", "begin_checkout", {
    currency: currency,
    value: Number(value + '.00'),
    coupon: "SUMMER_FUN",
    items: [
      {
        item_id: item_id,
        item_name: item_name,
        index: 0,
        item_brand: "Karina Grant",
        item_category: "Workshop",
        item_category2: "Quantum-Touch",
        item_category3: "Energy Healing",
        price: Number(item_price),
        quantity: 1
      }
    ]
  })
}

export const analyticViewItem  = (
  currency,
  value, 
  item_id,
  item_name
) => {
  if (typeof window.gtag !== 'undefined') {
        window.gtag("event", "view_item", {
          currency: currency,
          value: Number(value),
          items: [
            {
              item_id: item_id,
              item_name: item_name,
              affiliation: "Google Merchandise Store",
              index: 0,
              item_brand: "Karina Grant",
              item_category: "Energy Healing",
              item_category2: "Workshop",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              // item_variant: "green",
              // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
              price: Number(value),
              quantity: 1
            }
          ]          
        })
      }
  }

  export const analyticPurchase = (
    action,
    category, 
    label,
    transaction_id, 
    transaction_value, 
    transaction_currency, 
    transaction_tax,
    itemid,
    item_name,
    item_price
  ) => {
    // console.log('action', action)
    // console.log('category', category)
    // console.log('label', label)
    // console.log('transaction_id', transaction_id)
    // console.log('transaction_value', transaction_value)
    // console.log('transaction_currency', transaction_currency)
    // console.log('transaction_tax', transaction_tax)
    // console.log('itemid', itemid)
    // console.log('item_name', item_name)
    // console.log('item_price', item_price)
    if (typeof window.gtag !== 'undefined') {
      window.gtag("event", "purchase", {
        transaction_id: transaction_id,
        value: Number(transaction_value),
        tax: '',
        shipping: '',
        currency: transaction_currency,
        items: [
          {
            item_id: itemid,
            item_name: item_name,
            item_brand: "Karina Grant",
            list_name: "Karina Grant Events",
            item_category: "Events",
            item_price : Number(item_price)
          }
        ]
      })
    }
  }